import React, { useEffect, useState } from 'react';
import { Autocomplete, FormControl, IconButton, InputAdornment, TextField } from '@mui/material';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { toast } from 'react-toastify';
import PhoneIcon from '@mui/icons-material/Phone';
import LockIcon from '@mui/icons-material/Lock';
import zxcvbn from "zxcvbn";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Link, useNavigate } from 'react-router-dom';
import { baseURL } from '../../Services/Api';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import Box from "@mui/material/Box";
import * as Apiservice from "../../Services/Api";


function SignUpPage_new() {
    const type_of_org = [
        { label: 'Farmer group', id: 1 },
    ];

    const fpo_types = [
        { name: "FPO/FPC (Farmer Producer Organisation/Farmer Producer Company)" },
        { name: "PACS (Primary Agriculture Credit Society)" },
        { name: "Co-operatives" },
        { name: "FIG (Farmer Interest Group)" },
        { name: "Individual Proprietors" },
        { name: "Agri Input Dealers" },
        { name: "Others" },
    ];
    const [type_of_organization, setType_of_organization] = useState<{ label: string; id: number; } | null>(null);
    const [fpo_type, setFpo_type] = useState<{ name: string } | null>(null);
    const [checkemail, setCheckEmail] = useState(false);
    const [email1, setEmail1] = useState("");
    const [message, setMessage] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [showPassword1, setShowPassword1] = useState(false);
    const [password1, setPassword1] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(true); // State to track if passwords match
    const [mobilecheck, setMobileCheck] = useState("");
    const [mobileExist, setMobileExist] = useState(false);
    const [mobile, setMobile] = useState("");
    const [nameOfFpo, setNameOfFpo] = useState("");
    const [promoterName, setPromoterName] = useState("");
    const [openOTPPopUP, setOpenOTPPopUP] = useState(false);
    const [timer, setTimer] = useState(30); // 30 seconds countdown
    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const [verified, setVerified] = useState(false);
    const [otp, setOtp] = useState("");
    const [otpsent, setOtpSent] = useState(false);
    const [otpverification, setOtpVerification] = useState(false);
    const [verifiedMessage, setVerifiedMessage] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [verifyLoading, setVerifyLoading] = useState(false);
    const [signUpdisabled, setSignUpDisabled] = useState(true);

    const navigate = useNavigate();

    const handle_type_of_organization = (event: any, newValue: { label: string; id: number; } | null) => {
        setType_of_organization(newValue);
        console.log(newValue);
    };

    const handle_fpo_type = (event: any, newValue: { name: string } | null) => {
        setFpo_type(newValue);
        console.log(newValue);
    };
    useEffect(() => {
        let isMounted = true; // Flag to track if the component is still mounted
        const fetchData = async () => {
            try {
                const response = await fetch(`${baseURL}/whatsapp/send-otp`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({ phoneNumber: mobile })
                });

                if (response && response.ok) {
                    const data = await response.json();
                    if (isMounted) {
                        setVerified(true); // Update state only if the component is still mounted
                    }
                } else {
                    throw new Error("Failed to send OTP");
                }
            } catch (error) {
                console.error(error);
                toast.error("Something went wrong!");
            }
        };

        if (otpsent) {
            fetchData();
        }

        // Cleanup function to prevent state updates on an unmounted component
        return () => {
            isMounted = false;
        };
    }, [otpsent, mobile, baseURL]); // Include all dependencies

    useEffect(() => {
        if (otpverification) {
            setVerifyLoading(true);
            try {
                const fetchData = async () => {
                    const response = await fetch(`${baseURL}/whatsapp/check-otp`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({ phoneNumber: mobile, otp: otp })
                    });

                    if (response && response.ok) {
                        const data = await response.json();
                        console.log(data);
                        setSignUpDisabled(false);
                        setVerifiedMessage("Mobile number verified");
                        setIsButtonDisabled(true);
                        toast.success("Mobile number verified");
                        setOpenOTPPopUP(false);
                        setOtpVerification(false);
                        setVerified(true);
                    }
                    else {
                        const errorData = await response.json();
                        console.log(errorData);
                        if (errorData.message) {
                            toast.error("Invalid OTP");
                            setVerifiedMessage("Invalid OTP");
                        }
                        setOtpVerification(false);
                    }
                    setVerifyLoading(false);
                }
                fetchData();
            } catch (error) {
                console.log(error);
                toast.error("Something went wrong!");
            }
            finally {
                setVerifyLoading(false); // Ensure loading is stopped in case of errors
            }
        }
    }, [otpverification])
    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const password = event.target.value;
        const result = zxcvbn(password);
        setPassword(password);
        checkPasswordsMatch(password, password1); // Check if passwords match
        switch (result.score) {
            case 0:
                setMessage("Password: Very Weak");
                break;
            case 1:
                setMessage("Password: Weak");
                break;
            case 2:
                setMessage("Password: Fair");
                break;
            case 3:
                setMessage("Password: Strong");
                break;
            case 4:
                setMessage("Password: Very Strong");
                break;
            default:
                setMessage("");
                break;
        }
    };



    const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const confirmPassword = event.target.value;
        setPassword1(confirmPassword);
        checkPasswordsMatch(password, confirmPassword); // Check if passwords match
    };

    const checkPasswordsMatch = (password: string, confirmPassword: string) => {
        if (password === confirmPassword) {
            setPasswordsMatch(true); // Passwords match
        } else {
            setPasswordsMatch(false); // Passwords do not match
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    const handleClickShowPassword1 = () => {
        setShowPassword1(!showPassword1);
    };

    const handleMouseDownPassword1 = (event: any) => {
        event.preventDefault();
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const emailValue = event.target.value;
        setEmail1(emailValue);
        // validateEmail(emailValue);
    };

    const handleMobileCheck = async (event: any) => {
        const mobileValue = event.target.value;

        if (mobileValue.length === 10) {
            setMobileCheck("");
            setMobile(mobileValue);
        } else {
            setMobileCheck("Mobile number should be 10 digits");
            return;
        }

        try {
            const response = await fetch(`${baseURL}/check-contact/${mobileValue}`, {
                method: "GET",
                headers: { "Content-Type": "application/json" }
            });
            const data = await response.json();

            if (data.success) {
                setMobileExist(true);
                toast.error("Mobile number already exists");
                toast.error("Please use another mobile number");
            } else {
                setMobileExist(false);
            }

            setIsButtonDisabled(data.success); // Simplified logic
        } catch (error) {
            console.error(error);
            toast.error("Something went wrong!");
        }
    };


    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const fpoData = {
            typeOfOrganization: type_of_organization?.label || "",
            nameOfFpo: nameOfFpo,
            typeOfFpo: fpo_type?.name || "",
            dateOfFpo: "", // Not mandatory
            organizationalEmail: email1,
            contactNumber: mobile,
            promoterName: promoterName, // Capture this from an input field
            password: password
        };
        setLoading(true);
        const [error, response] = await Apiservice.dealerRegistration(
            fpoData.typeOfOrganization,
            fpoData.nameOfFpo,
            fpoData.typeOfFpo,
            fpoData.organizationalEmail,
            fpoData.contactNumber,
            fpoData.password,
            fpoData.promoterName
        );

        if (error) {
            console.error("Error:", error);
            toast.error(error.data, { position: toast.POSITION.TOP_RIGHT });
        } else {
            if (response && response.data) {
                toast.success("FPO Registered Successfully!");
                navigate("/login"); // Redirect on success
            } else {
                toast.error("Registration failed!");
            }
        }
        setLoading(false); // Ensure loading is stopped in case of errors
    };


    useEffect(() => {
        if (openOTPPopUP) {
            setTimer(30); // Reset timer on opening popup
            setIsResendDisabled(true);
            const interval = setInterval(() => {
                setTimer((prev) => {
                    if (prev === 1) {
                        clearInterval(interval);
                        setIsResendDisabled(false);
                    }
                    return prev - 1;
                });
            }, 1000);
            return () => clearInterval(interval); // Cleanup timer when popup closes
        }
    }, [openOTPPopUP]);

    const handleResendOTP = () => {
        setIsResendDisabled(true);
        setTimer(30);
        setOtpSent(true)// Restart timer
        // TODO: Add logic to resend OTP

    };

    return (
        <>
            {verifyLoading && (
                <Box sx={{ width: '100%', color: 'grey.500' }} className='flex justify-center items-center z-50'>
                    <CircularProgress color="success" />
                </Box>
            )}
            {loading && (
                <Box sx={{ width: '100%', color: 'grey.500' }} >
                    <LinearProgress color="success" />
                </Box>
            )}
            < img src="/Images/logoname.png" alt="" className="h-16 w-40 ml-5" />
            <div className='flex flex-col md:flex-row justify-around items-center pb-5'>
                <div className='flex justify-start'>
                    <form onSubmit={handleSubmit}>
                        <FormControl className='space-y-5'>
                            <h1 className='text-xl text-left font-semibold'>Sign Up</h1>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={type_of_org}
                                value={type_of_organization}
                                onChange={handle_type_of_organization}
                                isOptionEqualToValue={(option, value) => option.id === value.id}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Type of Organization*"
                                        variant="outlined"
                                        size="small"
                                        className='ml-0'
                                        required
                                        sx={{
                                            width: '380px',
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '4px',
                                                padding: '4px 8px',
                                                fontSize: '14px',
                                                backgroundColor: '#fff',
                                                border: '1px solid #616161',
                                            },
                                        }}
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PermIdentityOutlinedIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                                sx={{
                                    width: '330px',
                                    '& .MuiAutocomplete-popupIndicator': {
                                        color: '#000',
                                    },
                                    '& .MuiAutocomplete-input': {
                                        padding: '6px 8px',
                                        fontSize: '14px',
                                    },
                                }}
                            />
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo1"
                                options={fpo_types}
                                value={fpo_type}
                                onChange={handle_fpo_type}
                                getOptionLabel={(option) => option.name}
                                isOptionEqualToValue={(option, value) => option.name === value.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Type of Fpo*"
                                        variant="outlined"
                                        size="small"
                                        required
                                        sx={{
                                            width: '380px',
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '4px',
                                                padding: '4px 8px',
                                                fontSize: '14px',
                                                backgroundColor: '#fff',
                                                border: '1px solid #616161',
                                            },
                                        }}
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PermIdentityOutlinedIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                                sx={{
                                    width: '330px',
                                    '& .MuiAutocomplete-popupIndicator': {
                                        color: '#000',
                                    },
                                    '& .MuiAutocomplete-input': {
                                        padding: '6px 8px',
                                        fontSize: '14px',
                                    },
                                }}
                            />
                            <TextField
                                className=""
                                type="text"
                                required
                                size='small'
                                variant='outlined'
                                name="nameofFPO"
                                placeholder="Name of FPO"
                                id="nameofFPO"
                                onChange={(e) => setNameOfFpo(e.target.value)}
                                sx={{
                                    width: '380px',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '4px',
                                        padding: '0px 8px',
                                        fontSize: '14px',
                                        backgroundColor: '#fff',
                                        border: '1px solid #616161',
                                    },
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <>
                                            <InputAdornment position="start">
                                                <PermIdentityOutlinedIcon />
                                            </InputAdornment>
                                        </>
                                    ),
                                }}
                            />
                            <TextField
                                placeholder="Phone Number"
                                variant='outlined'
                                type="tel"
                                size='small'
                                required
                                onChange={handleMobileCheck}
                                sx={{
                                    width: '380px',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '4px',
                                        padding: '1px 8px',
                                        fontSize: '14px',
                                        backgroundColor: '#fff',
                                        border: '1px solid #616161',
                                        paddingRight: '0px',
                                    },
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <>
                                            <InputAdornment position="start">
                                                <PhoneIcon />
                                            </InputAdornment>
                                        </>
                                    ),
                                    endAdornment: (
                                        <>
                                            <InputAdornment position="end" sx={{ marginRight: '-5px' }}>
                                                <button className={`bg-[#3fc041] w-20 h-10 font-medium ${isButtonDisabled ? 'cursor-not-allowed' : ''}`} type='button' onClick={(e) => {
                                                    setOpenOTPPopUP(true);
                                                    setOtpSent(true);
                                                    if (verifiedMessage) {
                                                        setIsButtonDisabled(true);
                                                    }
                                                }}
                                                    disabled={isButtonDisabled}
                                                >Send OTP</button>
                                            </InputAdornment>
                                        </>
                                    )
                                }}
                            />

                            {openOTPPopUP && (
                                <div className='fixed top-[-20px] left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50'>
                                    <div className='bg-white w-[400px] min-h-[300px] rounded-lg p-5'>
                                        <h1 className='text-xl font-semibold'>Check WhatsApp</h1>
                                        <p>Enter Security code sent to your WhatsApp</p>
                                        <TextField
                                            className="p- rounded-xl border type-field"
                                            type='text'
                                            margin="normal"
                                            required
                                            fullWidth
                                            name="otp"
                                            placeholder="OTP"
                                            id="otp"
                                            autoComplete="current-otp"
                                            onChange={(e) => setOtp(e.target.value)}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: '4px',
                                                    padding: '4px 8px',
                                                    fontSize: '14px',
                                                    backgroundColor: '#fff',
                                                    border: '1px solid #616161',
                                                },
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <>
                                                        <InputAdornment position="start">
                                                            <ShieldOutlinedIcon />
                                                        </InputAdornment>
                                                    </>
                                                ),
                                            }}
                                        />
                                        <p className='text-sm text-left text-[#606060]'>
                                            Didn't receive the code?{" "}
                                            <button
                                                className={`text-[#3390ff] ${isResendDisabled ? "opacity-50 cursor-not-allowed" : ""}`}
                                                type='button'
                                                onClick={(e) => {
                                                    e.preventDefault(); // Prevent form validation error
                                                    handleResendOTP();

                                                }}
                                                disabled={isResendDisabled}
                                            >
                                                Resend {isResendDisabled && `(${timer}s)`}
                                            </button>
                                        </p>
                                        <button className='rounded-lg p-2 mt-5 w-[45%] bg-white text-black border-[1px] border-[#616161]' onClick={() => setOpenOTPPopUP(false)}>Cancel</button>
                                        <button className='bg-[#3390ff] text-white rounded-lg p-2 mt-5 w-[45%]' onClick={() => { setOtpVerification(true); }} type='button'>Verify</button>

                                        <p className='text-red-500'>{verifiedMessage}</p>
                                    </div>
                                </div>
                            )}
                            {mobilecheck && (
                                <p className="text-sm text-left text-red-500">{mobilecheck}</p>
                            )}
                            {verifiedMessage && (
                                <p className="text-sm text-left text-green-500">{verifiedMessage}</p>
                            )}
                            <TextField
                                placeholder="Email*"
                                variant="outlined"
                                type='email'
                                size="small"
                                required
                                onChange={handleEmailChange}
                                sx={{
                                    width: '380px',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '4px',
                                        padding: '4px 8px',
                                        fontSize: '14px',
                                        backgroundColor: '#fff',
                                        border: '1px solid #616161',
                                    },
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EmailOutlinedIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                placeholder="Promoter Name*" variant="outlined" type='text' size="small"
                                onChange={(e) => setPromoterName(e.target.value)}
                                required
                                sx={{
                                    width: '380px',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '4px',
                                        padding: '4px 8px',
                                        fontSize: '14px',
                                        backgroundColor: '#fff',
                                        border: '1px solid #616161',
                                    },
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PermIdentityOutlinedIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                placeholder="Password*"
                                variant="outlined"
                                type={showPassword ? 'text' : 'password'}
                                size="small"
                                onChange={handlePasswordChange}
                                required
                                sx={{
                                    width: '380px',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '4px',
                                        padding: '4px 8px',
                                        fontSize: '14px',
                                        backgroundColor: '#fff',
                                        border: '1px solid #616161',
                                    },
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockIcon />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }} />
                            <TextField
                                placeholder="Confirm Password*"
                                variant="outlined"
                                type={showPassword1 ? 'text' : 'password'}
                                size="small"
                                required
                                onChange={handleConfirmPasswordChange}
                                sx={{
                                    width: '380px',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '4px',
                                        padding: '4px 8px',
                                        fontSize: '14px',
                                        backgroundColor: '#fff',
                                        border: '1px solid #616161',
                                    },
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockIcon />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword1}
                                                onMouseDown={handleMouseDownPassword1}
                                                edge="end"
                                            >
                                                {showPassword1 ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }} />
                            <div className='flex justify-between'>
                                {!passwordsMatch && (
                                    <p className="text-sm text-left text-red-500">Passwords do not match.</p>
                                )}
                                {message && (
                                    <p className='text-sm text-left text-[#606060]'>{message}</p>
                                )}
                            </div>
                            <p className="text-black">By continuing, you agree to KrishiYan's <Link to="/terms" className="hover:underline cursor-pointer text-[#1976D2]">Terms & Conditions</Link>. <br /> Read our <Link to="/privacy" className="hover:underline cursor-pointer text-[#1976D2]"
                            >Privacy Policy.</Link></p>

                            <button
                                type="submit"
                                className="bg-[#3390ff] text-white w-[380px] rounded-lg p-2"
                                onMouseDown={(event) => {
                                    if (!isButtonDisabled) {
                                        event.preventDefault(); // Prevent any accidental focus
                                        toast.error("Please verify your mobile number first");
                                    }
                                }}
                                disabled={signUpdisabled}
                                style={{ cursor: !isButtonDisabled ? "not-allowed" : "pointer" }}
                            >
                                Sign Up
                            </button>
                            <h1 className='text-center'>Already have an account? <a href="" className='text-[#3390ff]' onClick={() => navigate("/login")}>Log In</a></h1>
                        </FormControl>
                    </form>
                </div>
                <div className='hidden md:block md:w-1/3'>
                    <img src="/Images/login_image.png" alt="loading" className='w-[50%px] h-[75%]' />
                </div>
            </div>
        </>
    );
};

export default SignUpPage_new;