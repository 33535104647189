import React, { useState } from 'react'
import { Autocomplete, InputAdornment, Table, TextField } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import * as Apiservice from '../../../Services/Apiservice'
import { toast } from 'react-toastify'


function UserDeletion() {
    const [mobile, setMobile] = useState('')
    console.log('Mobile:', mobile);


    const navigate = useNavigate()
    const Deletion = () => {
        console.log('Deletion')
        navigate('/Deletion', { state: { mobile } })
    }

    const handlesubmit = async (e: any) => {
        e.preventDefault()
        const [error, response] = await Apiservice.mobileExist(mobile)
        if (error) {
            console.log('Error:', error)
            toast.error("Mobile number does not exist")
            return
        } else if (response) {
            toast.success("You've processed the deletion request successfully")
            Deletion()
        }
    }

    return (
        <div>
            <img src="/Images/logo.png" alt="loading..." className='w-[25%] mx-auto' />
            <form onSubmit={handlesubmit} className='m-5'>
                <div className='flex flex-col items-center'>
                    <label htmlFor="" className='text-left'>Enter Mobile Number</label>
                    <TextField
                        type="text"
                        placeholder='Mobile Number'
                        variant='outlined'
                        id="mobile"
                        className="mt-0"
                        onChange={(e) => setMobile(e.target.value)}
                        sx={{
                            width: '300px',
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '4px',
                                padding: '0px 0px',
                                fontSize: '14px',
                                backgroundColor: '#fff',
                                border: '1px solid #616161',
                                paddingRight: '0px',
                                height: '38px',
                            },
                        }}
                    />
                </div>
                <button className='bg-[#3fc041] text-white p-2 rounded-md mt-2' type='submit'>Delete Account</button>
            </form>
        </div>
    )
}

export default UserDeletion