import CryptoJS from "crypto-js";

const secretKey = process.env.REACT_APP_ENCRYPTION_KEY; // Read secret key from .env

export function generateHeaders(payload) {
    const timestamp = Math.floor(Date.now() / 1000); // Current Unix timestamp
    const dataToSign = `x-timestamp=${timestamp}&body=${JSON.stringify(payload)}`;

    // Encrypt using HMAC-SHA256
    const signature = CryptoJS.HmacSHA256(dataToSign, secretKey).toString(CryptoJS.enc.Hex);

    return {
        "x-timestamp": timestamp,
        "x-signature": signature,
        "Content-Type": "application/json",
    };
}
