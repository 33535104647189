import { dialogClasses, FormControl, InputAdornment, TextField } from '@mui/material';
import React, { useState } from 'react';
import PhoneIcon from '@mui/icons-material/Phone';
import { useNavigate } from 'react-router-dom';
import { baseURL } from '../../Services/Api';
import { toast } from 'react-toastify';
import LinearProgress from '@mui/material/LinearProgress';
import Box from "@mui/material/Box";
import OtpVerify from './Otp_verify';

function Forgot_Password_new() {
    const navigate = useNavigate();
    const [mobileNumber, setMobileNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [mobile_exists, setMobile_exists] = useState(false);


    const handleResendOTP = async () => {
        // Resend OTP
        console.log('Resend OTP');
    };


    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!mobileNumber) {
            alert('Please enter your mobile number.');
            return;
        }

        setLoading(true);

        try {
            // Check if the mobile number exists
            const checkResponse = await fetch(`${baseURL}/check-contact/${mobileNumber}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const checkData = await checkResponse.json();

            if (!checkData.success === true) {
                console.error('Mobile number not registered:', checkData.message);
                toast.error('Mobile number not registered. Please try again.');
                return;
            }

            // If mobile number exists, send OTP
            const otpResponse = await fetch(`${baseURL}/whatsapp/send-otp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ phoneNumber: mobileNumber }),
            });

            if (otpResponse.ok) {

                console.log('OTP sent successfully');
                toast.success('OTP sent successfully. Please check your WhatsApp.');
                navigate('/otp-verify');
                localStorage.setItem('mobileNumber', mobileNumber);
            } else {
                console.error('Failed to send OTP:', otpResponse.statusText);
                alert('Failed to send OTP. Please try again.');
            }
        } catch (error) {
            console.error('Failed to send OTP:', error);
            toast.error('An error occurred. Please try again.');
        } finally {
            setLoading(false); // Ensure loading is stopped in case of errors
        }
    };

    return (
        <>
            {loading &&
                <Box sx={{ width: '100%', color: 'grey.500' }} >
                    <LinearProgress color="success" />
                </Box>
            }
            <img src="/Images/logoname.png" alt="" className="h-16 w-40 ml-5" />
            <div className='flex justify-around items-center'>
                <div className='p-5' >
                    <form onSubmit={handleSubmit}>
                        <FormControl className='space-y-5'>
                            <h1 className='text-left font-bold text-2xl'>Forgot Password?</h1>
                            <TextField
                                margin="normal"
                                required
                                size='small'
                                type='tel'
                                variant='outlined'
                                id="contactNumber"
                                placeholder='Enter your Mobile Number'
                                name="contactNumber"
                                autoFocus
                                className="custom-textfield"
                                onChange={(e) => setMobileNumber(e.target.value)}
                                sx={{
                                    width: '350px',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '4px',
                                        padding: '1px 8px',
                                        fontSize: '14px',
                                        backgroundColor: '#fff',
                                        border: '1px solid #616161',
                                        paddingRight: '0px',
                                    },
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PhoneIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <button type="submit" className='bg-[#3390ff] p-3'>Reset Password</button>
                            <p>Get back to <a href="" className='text-[#3390ff]' onClick={(e) => { e.preventDefault(); navigate("/login"); }}>Login</a></p>
                        </FormControl>
                    </form>
                </div>
                <div className='hidden md:block w-1/3'>
                    <img src="/Images/Forgot.png" alt="loading" />
                </div>
            </div>
        </>
    );
}

export default Forgot_Password_new;