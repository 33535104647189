import { FormControl, LinearProgress, TextField } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box } from '@material-ui/core';
import { baseURL } from '../../Services/Api';

function Otp_verify() {
    const [otp, setOtp] = useState<string[]>(['', '', '', '']);
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const [timer, setTimer] = useState(30); // 30 seconds countdown
    const sendOTP = location.state?.sendOTP || (() => Promise.resolve());

    const mobile = localStorage.getItem('mobileNumber');

    const handleChange = (index: number, value: string) => {
        if (!/^\d?$/.test(value)) return; // Allow only digits
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        // Move to the next input field if a digit is entered
        if (value && index < 3) {
            inputRefs.current[index + 1]?.focus();
        }
    };

    const handleKeyDown = (index: number, e: React.KeyboardEvent) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            inputRefs.current[index - 1]?.focus();
        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setLoading(true);
        try {
            const response = await fetch(`${baseURL}/whatsapp/check-otp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ phoneNumber: mobile, otp: otp.join('') }),
            });

            if (response.ok) {
                console.log('OTP Verified successfully');
                toast.success('OTP Verified successfully');
                navigate('/change-password');
            } else {
                console.error('Failed to verify OTP:', response.statusText);
                toast.error('Please enter the valid OTP');
            }
        } catch (error) {
            console.error('Failed to verify OTP:', error);
            toast.error('An error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (mobile) {
            setTimer(30); // Reset timer
            setIsResendDisabled(true);
            const interval = setInterval(() => {
                setTimer((prev) => {
                    if (prev === 1) {
                        clearInterval(interval);
                        setIsResendDisabled(false);
                    }
                    return prev - 1;
                });
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [mobile]);

    const handleResendOTP = async () => {
        if (!mobile) return;

        setIsResendDisabled(true);
        setTimer(30);

        try {
            await fetch(`${baseURL}/whatsapp/send-otp`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ phoneNumber: mobile }),
            });
            await sendOTP(mobile); // Now correctly calls sendOTP
            toast.success('OTP resent successfully.');
        } catch (error) {
            console.error('Failed to resend OTP:', error);
            toast.error('Failed to resend OTP. Please try again.');
        }
    };
    return (
        <>
            {loading && (
                <Box sx={{ width: '100%', color: 'grey.500' }}>
                    <LinearProgress color="success" />
                </Box>
            )}
            <img src="/Images/logoname.png" alt="" className="h-16 w-40 ml-5" />
            <div className="flex justify-around items-center">
                <div className="p-5">
                    <form onSubmit={handleSubmit}>
                        <FormControl className="space-y-5">
                            <h1 className="text-left font-bold text-2xl">Check Your WhatsApp</h1>
                            <h1 className="text-left font-thin text-[#616161] text-md">
                                Enter Security code sent to your WhatsApp
                            </h1>
                            <div className="flex space-x-3 justify-start">
                                {otp.map((digit, index) => (
                                    <TextField
                                        key={index}
                                        inputRef={(el) => (inputRefs.current[index] = el)}
                                        value={digit}
                                        onChange={(e) => handleChange(index, e.target.value)}
                                        onKeyDown={(e) => handleKeyDown(index, e)}
                                        inputProps={{
                                            maxLength: 1,
                                            className: 'text-center text-xl font-bold',
                                            style: { width: '30px', height: '30px' },
                                            inputMode: 'numeric',
                                        }}
                                        variant="outlined"
                                    />
                                ))}
                            </div>
                            <div className="flex justify-start">
                                <button
                                    type="button"
                                    className="bg-white border-[1px] border-black text-black p-3 w-[40%]"
                                >
                                    Cancel
                                </button>
                                <button type="submit" className="bg-[#3390ff] w-[40%] p-3 rounded text-white">
                                    Verify OTP
                                </button>
                            </div>

                            <p className="text-sm text-left text-[#606060]">
                                Didn't receive the code?{' '}
                                <button
                                    className={`text-[#3390ff] ${isResendDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    type="button"
                                    onClick={handleResendOTP}
                                    disabled={isResendDisabled}
                                >
                                    Resend {isResendDisabled && `(${timer}s)`}
                                </button>
                            </p>
                        </FormControl>
                    </form>
                </div>
                <div className="hidden md:block w-1/3">
                    <img src="/Images/otp_verify.png" alt="loading" />
                </div>
            </div>
        </>
    );
}

export default Otp_verify;