import { FormControl, IconButton, InputAdornment, LinearProgress, TextField } from '@mui/material';
import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { baseURL } from '../../Services/Api';
import { toast } from 'react-toastify';
import { Box } from '@material-ui/core';
import LockIcon from '@mui/icons-material/Lock';
import zxcvbn from "zxcvbn";
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';


function Change_password() {
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [password1, setPassword1] = useState('');
    const [message, setMessage] = useState("");
    const [showPassword1, setShowPassword1] = useState(false);
    const navigate = useNavigate();

    let mobile = localStorage.getItem('mobileNumber');

    console.log(mobile);


    const checkPasswordsMatch = (password: string, confirmPassword: string) => {
        if (password === confirmPassword) {
            setPasswordsMatch(true); // Passwords match
        } else {
            setPasswordsMatch(false); // Passwords do not match
        }
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const password = event.target.value;
        const result = zxcvbn(password);

        setPassword(password);
        checkPasswordsMatch(password, password1); // Check if passwords match
        switch (result.score) {
            case 0:
                setMessage("Password: Very Weak");
                break;
            case 1:
                setMessage("Password: Weak");
                break;
            case 2:
                setMessage("Password: Fair");
                break;
            case 3:
                setMessage("Password: Strong");
                break;
            case 4:
                setMessage("Password: Very Strong");
                break;
            default:
                setMessage("");
                break;
        }
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    const handleClickShowPassword1 = () => {
        setShowPassword1(!showPassword1);
    };

    const handleMouseDownPassword1 = (event: any) => {
        event.preventDefault();
    };

    const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const confirmPassword = event.target.value;
        setPassword1(confirmPassword);
        checkPasswordsMatch(password, confirmPassword); // Check if passwords match
    };

    const handlesubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log(password);

        if (passwordsMatch) {
            setLoading(true);
            try {
                const response = await fetch(`${baseURL}/app/reset-password`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        contactNumber: mobile,
                        newPassword: password,
                    }),
                });
                const data = await response.json();
                if (data.success) {
                    setLoading(false);
                    toast.success(data.message);
                    navigate('/login');
                } else {
                    setLoading(false);
                    toast.error(data.message);
                    console.log(data.message);

                }
            } catch (error) {
                setLoading(false);
                toast.error('An error occurred. Please try again.');
            }
        }
    };

    return (
        <>
            {loading && (
                <Box sx={{ width: '100%', color: 'grey.500' }}>
                    <LinearProgress color="success" />
                </Box>
            )}
            <img src="/Images/logoname.png" alt="" className="h-16 w-40 ml-5" />
            <div className="flex justify-around items-center">
                <div>
                    <form onSubmit={handlesubmit}>
                        <FormControl className="space-y-5">
                            <h1 className="text-left font-bold text-2xl">Set New Password</h1>
                            <TextField
                                placeholder="Password*"
                                variant="outlined"
                                type={showPassword ? 'text' : 'password'}
                                size="small"
                                onChange={handlePasswordChange}
                                sx={{
                                    width: '380px',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '4px',
                                        padding: '4px 8px',
                                        fontSize: '14px',
                                        backgroundColor: '#fff',
                                        border: '1px solid #616161',
                                    },
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockIcon />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }} />
                            <TextField
                                placeholder="Confirm Password*"
                                variant="outlined"
                                type={showPassword1 ? 'text' : 'password'}
                                size="small"
                                onChange={handleConfirmPasswordChange}
                                sx={{
                                    width: '380px',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '4px',
                                        padding: '4px 8px',
                                        fontSize: '14px',
                                        backgroundColor: '#fff',
                                        border: '1px solid #616161',
                                    },
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockIcon />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword1}
                                                onMouseDown={handleMouseDownPassword1}
                                                edge="end"
                                            >
                                                {showPassword1 ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }} />
                            <div className='flex justify-between'>
                                {!passwordsMatch && (
                                    <p className="text-sm text-left text-red-500">Passwords do not match.</p>
                                )}
                                {message && (
                                    <p className='text-sm text-left text-[#606060]'>{message}</p>
                                )}
                            </div>
                            <div>
                                <button type="submit" className="bg-[#3390ff] w-[45%] p-3 rounded text-white">
                                    Reset Password
                                </button>
                            </div>
                        </FormControl>
                    </form>
                </div>
                <div className="hidden md:block w-1/3">
                    <img src="/Images/change-pwd.png" alt="loading" />
                </div>
            </div>
        </>
    )
}

export default Change_password