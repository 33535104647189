import * as axios from "axios";
import { generateHeaders } from "../utils/generateHeaders";


const apiURL = process.env.REACT_APP_BACKEND_URL;
export const baseURL = "https://krishiyan-backend-teal.vercel.app/api";


interface ResponseData {
    data: any;
    status: any;
}

function normalizeServerResponse(serverResponse: any) {
    let response: ResponseData = {
        data: serverResponse.data,
        status: serverResponse.status,
    };

    return response;
}

function normalizeServerError(serverResponse: any) {
    let response: ResponseData = {
        data: serverResponse.response?.data?.message,
        status: serverResponse.status,
    };

    return response;
}

// SIGN UP// FPO REGISTRATION
export async function dealerRegistration(
    typeOfOrganization: any,
    nameOfFpo: any,
    typeOfFpo: any,
    dateOfFpo: any,
    organizationalEmail: any,
    contactNumber: any,
    promoterName: any, // Capture this from an input field
    password: any
) {
    try {
        const payload = {
            typeOfOrganization,
            nameOfFpo,
            typeOfFpo,
            dateOfFpo,
            organizationalEmail,
            contactNumber,
            promoterName,
            password,
        };
        const headers = generateHeaders(payload); // ✅ Generate headers inside function
        console.log(nameOfFpo, organizationalEmail, "object");
        const axiosConfig: axios.AxiosRequestConfig = {
            method: "post",
            url: `${baseURL}/fpo`,
            data: payload,
            headers: headers, // ✅ Attach headers internally
        };
        const response = await axios.default.request(axiosConfig);
        console.log(response);
        const normalizedResponse = normalizeServerResponse(response);
        return [null, normalizedResponse];
    } catch (error) {
        const errorObject = normalizeServerError(error);
        return [errorObject, null];
    }
}

//Whatsapp OTP
export async function sendOtp(contactNumber: any) {
    try {
        const payload = { contactNumber }; // Create request payload
        const headers = generateHeaders(payload); // ✅ Generate headers inside function

        const axiosConfig: axios.AxiosRequestConfig = {
            method: "post",
            url: `${baseURL}/app/send-otp`,
            data: payload,
            headers: headers, // ✅ Attach headers internally
        };

        const response = await axios.default.request(axiosConfig);
        const normalizedResponse = normalizeServerResponse(response);
        return [null, normalizedResponse];
    } catch (error) {
        const errorObject = normalizeServerError(error);
        return [errorObject, null];
    }
}


//Login
export async function dealerLogin(contactNumber: any, password: any) {
    try {
        const payload = { contactNumber, password }; // Create request payload
        const headers = generateHeaders(payload); // ✅ Generate headers inside function

        const axiosConfig: axios.AxiosRequestConfig = {
            method: "post",
            url: `${baseURL}/app/sign-in`,
            data: payload,
            headers: headers, // ✅ Attach headers internally
        };

        const response = await axios.default.request(axiosConfig);
        const normalizedResponse = normalizeServerResponse(response);
        return [null, normalizedResponse];
    } catch (error) {
        const errorObject = normalizeServerError(error);
        return [errorObject, null];
    }
}

//mobile exist
export async function mobileExist(contactNumber: any) {
    try {
        const payload = { contactNumber }; // Create request payload
        const headers = generateHeaders(payload); // ✅ Generate headers inside function

        const axiosConfig: axios.AxiosRequestConfig = {
            method: "get",
            url: `${baseURL}/check-contact/${contactNumber}`,
            data: payload,
            headers: headers, // ✅ Attach headers internally
        };

        const response = await axios.default.request(axiosConfig);
        const normalizedResponse = normalizeServerResponse(response);
        return [null, normalizedResponse];
    } catch (error) {
        const errorObject = normalizeServerError(error);
        return [errorObject, null];
    }
}

//Farmer fetching
export async function fetchFarmer(mobile: any) {
    const payload = { mobile }; // Create request payload
    const headers = generateHeaders(payload);
    try {
        const axiosConfig: axios.AxiosRequestConfig = {
            method: "get",
            url: `${baseURL}/appFarmer/data/${mobile}`,
            headers: headers,
        };

        const response = await axios.default.request(axiosConfig);
        const normalizedResponse = normalizeServerResponse(response);
        return [null, normalizedResponse];
    } catch (error) {
        const errorObject = normalizeServerError(error);
        return [errorObject, null];
    }
}

export async function insights(selectedCrop: any, dealerNumber: any) {
    const payload = { selectedCrop, dealerNumber, }; // Create request payload
    const headers = generateHeaders(payload);
    try {
        const axiosConfig: axios.AxiosRequestConfig = {
            method: "get",
            url: `${baseURL}/appFarmer/farmers/insight?dealerNumber=${dealerNumber}&crop=${selectedCrop}`,
            data: payload,
            headers: headers,
        };

        const response = await axios.default.request(axiosConfig);
        const normalizedResponse = normalizeServerResponse(response);
        return [null, normalizedResponse];
    } catch (error) {
        const errorObject = normalizeServerError(error);
        return [errorObject, null];
    }
}