import React from 'react'
import { useLocation } from 'react-router-dom';

function Deletion() {
    const location = useLocation();
    const mobile = location.state?.mobile || '';
    return (
        <div className='text-left p-5 border-2 border-[#474747] rounded-md m-5 w-auto'>
            <h1 className='text-2xl font-semibold text-[#474747]'>Account Deletion Information</h1>
            <p className='font-semibold text-xl'>To request account deletion for mobile number: {mobile} </p>
            <p className='text-xl'>The account deletion process will be completed within 24 hours.</p>
            <p className='text-red-600 text-xl'>Note: All your data associated with the mobile number will be deleted and cannot be retreieved.</p>
        </div>
    )
}

export default Deletion