import React, { useEffect, useRef, useState } from 'react';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import * as Api from "../../../Services/Api";
import { toast } from 'react-toastify';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import BulkUploadFarmers from './BulkUploadFarmers';
import axios from 'axios';
import saveAs from 'file-saver';
import CSVReader from 'react-csv-reader';
import { baseURL } from '../../../Services/Api';
import { Autocomplete, InputAdornment, LinearProgress, Table, TextField } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import CircularProgress from '@mui/material/CircularProgress';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import { METHODS } from 'http';
import { json } from 'stream/consumers';


interface ApiResponse {
    message: string;
    errors?: { farmer: { name: string }; reason: string }[]; // 'errors' is optional
}

function Farmer_registration() {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isRegistrationPopupOpen, setIsRegistrationPopupOpen] = useState(false);
    const [farmersData, setFarmersData] = useState<any[]>([]);
    const [response, setResponse] = useState<ApiResponse | null>(null);

    const navigate = useNavigate();

    // console.log(response);

    const cultivation_practice = [
        { name: "Organic" },
        { name: "Inorganic" }
    ]

    const handle_option = (event: any, newValue: { name: string } | null) => {
        setTypeOfCultivation(newValue);
        console.log(newValue);
    };

    //1st PopUp
    const [name, setName] = useState('');
    const [whatsappNumber, setWhatsappNumber] = useState('');
    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const [timer, setTimer] = useState(30); // 30 seconds countdown
    const location = useLocation();
    const sendOTP = location.state?.sendOTP || (() => Promise.resolve());
    const [otpsent, setOtpsent] = useState(false)

    console.log(whatsappNumber);

    //2nd PopUp
    const [farmerName, setFarmerName] = useState('');
    const [contact, setContact] = useState('');
    const [pincode1, setPincode] = useState('');
    const [village1, setVillage] = useState('');
    const [district1, setDistrict] = useState('');
    const [state1, setState] = useState('');
    const [totalFarmArea1, setTotalFarmArea] = useState('');
    const [leasedArea, setLeasedArea] = useState(Number);
    const [ownedArea, setOwnedArea] = useState(Number);
    const [typeOfCultivation, setTypeOfCultivation] = useState<{ name: string } | null>(null);
    const [bankName, setBankName] = useState('');
    const [holderName, setHolderName] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [ifscCode, setIfscCode] = useState('');
    const [panNumber, setPanNumber] = useState('');
    const [aadhaarNumber, setAadhaarNumber] = useState('');
    const [pinerror, setPinerror] = useState("");

    const [otp, setOtp] = useState<string[]>(['', '', '', '']);
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);


    const [loading, setLoading] = useState(false);
    const [otploading, setOtploading] = useState(false)
    const [otpverified, setOtpverified] = useState(false)


    // 1st Popup functions
    const handleSentotp = async () => {
        setOtploading(true)
        if (!whatsappNumber || whatsappNumber === localStorage.getItem("dealermobile")) {
            toast('Please enter Valid mobile number.');
            setOtploading(false)
            return;
        }

        try {
            const response = await fetch(`${baseURL}/whatsapp/send-otp`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ phoneNumber: whatsappNumber })
            });
            if (response.ok) {
                console.log("OTP send succesfully");
                toast.success("OTP sent successfully")
                localStorage.setItem('whatsappNumber', whatsappNumber)
                setOtpsent(true)
            }
            else {
                toast.error("Error sending OTP")
            }
        } catch (error) {
            toast.error("Something went wrong")
            console.log(error);
        }
        finally {
            setOtploading(false)
        }
    }

    useEffect(() => {
        if (otpsent) {
            setTimer(30); // Reset timer
            setIsResendDisabled(true);
            const interval = setInterval(() => {
                setTimer((prev) => {
                    if (prev === 1) {
                        clearInterval(interval);
                        setIsResendDisabled(false);
                    }
                    return prev - 1;
                });
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [otpsent]);

    const handleResendOTP = async () => {
        if (!whatsappNumber) return;

        setIsResendDisabled(true);
        setTimer(30);
        setOtploading(true)

        try {
            await fetch(`${baseURL}/whatsapp/send-otp`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ phoneNumber: whatsappNumber }),
            });
            await sendOTP(whatsappNumber); // Now correctly calls sendOTP
            toast.success('OTP resent successfully.');
        } catch (error) {
            console.error('Failed to resend OTP:', error);
            toast.error('Failed to resend OTP. Please try again.');
        } finally {
            setOtploading(false)
        }
    };

    const handleChange = (index: number, value: string) => {
        if (!/^\d?$/.test(value)) return; // Allow only digits
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        // Move to the next input field if a digit is entered
        if (value && index < 3) {
            inputRefs.current[index + 1]?.focus();
        }
    };

    const handleKeyDown = (index: number, e: React.KeyboardEvent) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            inputRefs.current[index - 1]?.focus();
        }
    };


    const handleFileUpload = (data: any, event: any) => {
        const rows = data.slice(1);
        const jsonData = rows.map((row: any) => ({
            dealerNumber: localStorage.getItem('dealermobile'),
            name: row[0],
            whatsappNumber: row[1],
            totalOwnedFarm: Number(row[2]) || 0,
            geoLocationOwnedFarm: row[3],
            totalLeaseFarm: Number(row[4]) || 0,
            geoLocationLeaseFarm: row[5],
            pincode: row[6],
            village: row[7],
            district: row[8],
            state: row[9],
            address: row[10],
            typeOfCultivationPractice: row[11],
            bankName: row[12],
            accountName: row[13],
            accountNumber: row[14],
            ifscCode: row[15],
            pan: row[16],
            aadhaarNumber: row[17],
        }));
        setFarmersData(jsonData);
    };
    const handleUpload = async () => {
        setLoading(true);
        try {
            const apiResponse = await axios.post(`${baseURL}/appFarmer/farmer/bulk-upload`, farmersData);
            setResponse(apiResponse.data);
            toast.success("Farmers data uploaded successfully", {
                position: toast.POSITION.TOP_RIGHT
            });
        } catch (error) {
            console.error("Error uploading farmers data:", error);
            toast.error("Error uploading farmers data", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        setLoading(false);
    };

    const handleDownloadResponse = () => {
        const blob = new Blob([JSON.stringify(response, null, 2)], { type: "application/json" });
        saveAs(blob, "upload_response.json");
    };


    const togglePopup = () => {
        setIsPopupOpen(!isPopupOpen);
    };
    const closeRegistrationPopup = () => {
        setIsRegistrationPopupOpen(false);
    };
    const openRegistrationPopup = () => {
        setIsPopupOpen(false); // Close the OTP popup
        setIsRegistrationPopupOpen(true); // Open the registration popup
    };

    const onverification = async () => {

        setOtploading(true);
        try {
            const response = await fetch(`${baseURL}/whatsapp/check-otp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ phoneNumber: whatsappNumber, otp: otp.join('') }),
            });

            if (response.status === 200) {
                console.log('OTP Verified successfully');
                toast.success('OTP Verified successfully');
                setOtpverified(true)
                openRegistrationPopup()
                setOtpsent(false)
                setOtp(['', '', '', ''])
            } else {
                console.error('Failed to verify OTP:', response.statusText);
                toast.error('Please enter the valid OTP');
            }
        } catch (error) {
            console.error('Failed to verify OTP:', error);
            toast.error('An error occurred. Please try again.');
        } finally {
            setOtploading(false);
        }
    };
    const pincodeverification = async (event: React.FocusEvent<HTMLInputElement>) => {
        const pincode = event.target.value; // Get the value of the input field
        try {
            const [err, res] = await Api.postPincode(pincode);
            if (res) {
                if (res.data.PostOffice === null) {
                    toast.error('Invalid Pincode', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setPinerror("Invalid Pincode");
                    return;
                }
                const district = (res.data.PostOffice[0].District);
                const State = (res.data.PostOffice[0].State);
                setDistrict(district);
                setState(State);
                setPinerror("");
                setPincode(pincode);
            } else {
                console.error('Error:', err);

            }
        } catch (error) {
            console.error('Unexpected error:', error);
        }
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let name = farmerName;
        let whatsappNumber = localStorage.getItem('whatsappNumber');
        let pincode = pincode1;
        let village = village1;
        let district = district1;
        let state = state1;
        let totalLeaseFarm = leasedArea;
        let totalOwnedFarm = ownedArea;
        let typeOfCultivationPractice = typeOfCultivation?.name;
        let dealerNumber = localStorage.getItem('dealermobile');

        console.log(data);

        console.log({
            name,
            whatsappNumber,
            pincode,
            village,
            district,
            state,
            totalLeaseFarm,
            totalOwnedFarm,
            typeOfCultivationPractice,
            dealerNumber
        });
        const [err, res] = await Api.registerFarmer(
            dealerNumber,
            name,
            whatsappNumber,
            totalOwnedFarm,
            totalLeaseFarm,
            pincode,
            village,
            district,
            state,
            typeOfCultivationPractice
        );
        if (res) {
            console.log("Response:", res);
            toast.success("Farmer Registered Successfully", {
                position: toast.POSITION.TOP_RIGHT
            });
            setIsRegistrationPopupOpen(false)
        } else {
            console.error("Error:", err);
            if (err) {
                toast.error(err.data, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    return (
        <div className='p-4  h-[100%]'>
            <div className="bg-white h-[100%] rounded-md shadow-md">
                <div className="flex justify-between p-5">
                    <div className="text-left ml-5">
                        <h1 className="text-xl font-normal">Hello,</h1>
                        <p className="text-base text-[#3a3a3a]">
                            Easily Add farmers by either registering individually or uploading in bulk
                        </p>
                    </div>
                    <div className="mr-5">
                        <button
                            className="inline-flex p-2 rounded-sm bg-[#3fc041] text-white items-center"
                            onClick={togglePopup}
                        >
                            <PersonAddOutlinedIcon className="mr-1" />
                            Register Farmer
                        </button>
                    </div>
                </div>
                {/* BULK UPLOADING UI */}
                <div className="border-2 border-dashed border-gray-300 rounded-lg p-10 flex flex-col items-center w-4/5 m-auto h-auto justify-center">
                    <label
                        htmlFor="fileUpload"
                        className="cursor-pointer flex flex-col items-center text-center"
                    >
                        <CSVReader
                            onFileLoaded={handleFileUpload}
                            parserOptions={{ skipEmptyLines: true }}
                            cssClass="csv-reader-input"
                            inputId="csv-upload"
                            inputStyle={{ display: 'none' }} // Hide the default input
                        />
                        <label htmlFor="csv-upload" className="update-button">
                            <FileUploadOutlinedIcon className="mr-1" /> Upload Data
                        </label>

                        <style>
                            {`
                    .update-button {
                        background-color: #ffffff;
                        color: #3fc041;
                        border: 2px solid #3fc041;
                        padding: 8px 17px;
                        border-radius: 5px;
                        cursor: pointer;
                        font-size: 16px;
                        display: inline-block;
                        text-align: center;
                    }
                    .update-button:hover {
                        background-color: #3fc041;
                        color: #ffffff;
                    }
                 `}
                        </style>

                    </label>
                    <a href="/sample.csv" className='p-2'>Click here to download <span className='text-[#3fc041] underline'>sample.csv</span></a>
                    <p className="text-gray-500 mt-2 text-sm">
                        Upload farmer records in .xls or .xlsx format for bulk registration.
                    </p>
                    {farmersData.length > 0 && (
                        <button
                            onClick={handleUpload}
                            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-[#3390FF] mt-4"
                        >
                            Upload Farmers Data
                        </button>
                    )}
                    {response && (
                        <div
                            className={`mt-4 p-3 border-l-4 rounded-lg ${response.errors && response.errors.length > 0
                                ? "bg-red-50 border-red-400 rounded-lg border-l-4 text-red-400" // Red for errors
                                : "bg-white border-[#3fc041] border-[2px] border-l-4 rounded-lg text-[#3fc041]" // Green for success
                                }`}
                        >
                            <p className='text-left'>{(response as any)?.message || "No message available"}</p>
                            {response && 'errors' in response && (response as any).errors.length > 0 && (
                                <div>
                                    <h2 className="text-sm font-medium text-red-400 text-left">Same records in the uploaded file are duplicated. Please review the details below and correct them before re-uploading</h2>
                                    <Table className="w-full mt-4 border-collapse border text-red-500 border-red-700">
                                        <thead>
                                            <tr className="bg-red-200">
                                                <th className="border border-red-400 px-2 py-1">Sr. No.</th>
                                                <th className="border border-red-400 px-2 py-1">Farmer Name</th>
                                                <th className="border border-red-400 px-2 py-1">Reason</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(response as any)?.errors.map((error: any, index: number) => (
                                                <tr key={index} className="bg-red-50">
                                                    <td className="border border-red-700 px-2 py-1 text-center">{index + 1}</td>
                                                    <td className="border border-red-700 px-2 py-1">{error.farmer.name || "Unknown"}</td>
                                                    <td className="border border-red-700 px-2 py-1">{error.reason}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            )}
                            {/* <button
                                onClick={handleDownloadResponse}
                                className="mt-4 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                            >
                                Download Response
                            </button> */}

                        </div>
                    )}
                </div>
                {loading && (
                    <div className="bg-[#F0F0F0] p-4 rounded-lg shadow-lg w-[80%] mb-5 m-auto mt-4">
                        <h2 className="text-md font-medium text-left pb-4">Uploading Farmers Data...</h2>
                        <LinearProgress sx={{ backgroundColor: "#e0e0e0", "& .MuiLinearProgress-bar": { backgroundColor: "#3fc041" } }} />
                    </div>
                )}

                {/* Popup */}
                {isPopupOpen && (
                    <div>
                        <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                            <div className="bg-white w-[600px] rounded-lg shadow-lg p-6 relative">
                                {/* Close Button */}
                                <button
                                    className="absolute top-2 bg-white right-2 text-gray-500 text-xl font-bold"
                                    onClick={togglePopup}
                                >
                                    X
                                </button>
                                <h2 className="text-xl font-semibold mb-4">Farmer Registration</h2>
                                <form className="space-y-4">
                                    <div className='w-1/2 m-auto'>
                                        <label htmlFor="name" className="block text-sm font-medium text-left">
                                            Name
                                        </label>
                                        <TextField
                                            type="text"
                                            margin='normal'
                                            required
                                            // variant='outlined'
                                            id="name"
                                            className=""
                                            onChange={(e) => setName(e.target.value)}
                                            sx={{
                                                width: '300px',
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: '4px',
                                                    padding: '0px 0px',
                                                    fontSize: '14px',
                                                    backgroundColor: '#fff',
                                                    border: '1px solid #616161',
                                                    paddingRight: '0px',
                                                    height: '40px',
                                                },
                                            }}
                                        />
                                    </div>
                                    <div className='w-1/2 m-auto'>
                                        <label htmlFor="whatsappNumber" className="block text-sm font-medium text-left">
                                            WhatsApp Number
                                        </label>
                                        <div className='flex justify-right'>
                                            <div>
                                                <TextField
                                                    type="tel"
                                                    required
                                                    margin='normal'
                                                    // variant='outlined'
                                                    id="mobile"
                                                    className=""
                                                    onChange={(e) => setWhatsappNumber(e.target.value)}
                                                    sx={{
                                                        width: '300px',
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '4px',
                                                            padding: '0px 0px',
                                                            fontSize: '14px',
                                                            backgroundColor: '#fff',
                                                            border: '1px solid #616161',
                                                            paddingRight: '0px',
                                                            height: '40px',
                                                        },
                                                    }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <button
                                                                className={`bg-[#3fc041] text-white mr-0 px-4 py-[10px] rounded-md w-1/2 ${otpsent ? 'cursor-not-allowed' : ''}`}
                                                                onClick={handleSentotp}
                                                                disabled={otpsent}
                                                                type='button'
                                                            >
                                                                Send OTP
                                                            </button>
                                                        )
                                                    }}
                                                />
                                            </div>
                                            <div className='ml-5 mt-2'>
                                                {otploading && (
                                                    <div className="z-50 justify-center mt-2">
                                                        <CircularProgress color="success" />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <p className="text-sm text-right mr-24 text-[#606060]">
                                        Didn't receive the code?{' '}
                                        <button
                                            className={`text-[#3390ff] ${isResendDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                                            type="button"
                                            onClick={handleResendOTP}
                                            disabled={isResendDisabled}
                                        >
                                            Resend {isResendDisabled && `(${timer}s)`}
                                        </button>
                                    </p>

                                    <div className='w-1/2 m-auto'>
                                        <label htmlFor="otp" className="block text-sm font-medium text-left p-2">
                                            Enter OTP
                                        </label>
                                        <div className="flex space-x-3 justify-center">
                                            {otp.map((digit, index) => (
                                                <TextField
                                                    key={index}
                                                    inputRef={(el) => (inputRefs.current[index] = el)}
                                                    value={digit}
                                                    required
                                                    onChange={(e) => handleChange(index, e.target.value)}
                                                    onKeyDown={(e) => handleKeyDown(index, e)}
                                                    inputProps={{
                                                        maxLength: 1,
                                                        className: 'text-center text-xl font-bold',
                                                        style: { width: '20px', height: '20px' },
                                                        inputMode: 'numeric',
                                                    }}
                                                    variant="outlined"
                                                />
                                            ))}
                                        </div>
                                    </div>
                                    <div className="flex justify-center items-center w-1/2 m-auto">
                                        <button
                                            type="button"
                                            className="bg-[#3fc041] text-white px-4 py-2 rounded-md w-full"
                                            onClick={onverification}
                                        // style={{ cursor: otpverified ? "not-allowed" : "pointer" }}
                                        >
                                            Verify
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                )
                }
                {/* Farmer Registration Popup */}
                {
                    isRegistrationPopupOpen && (
                        <div>
                            <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>
                            <div className="fixed inset-0 flex justify-center items-center z-50">
                                <div className="bg-white w-[800px] rounded-lg shadow-lg p-6 relative">
                                    {/* Close Button */}
                                    <button
                                        className="absolute top-2 right-2 bg-white text-gray-500 text-xl font-bold"
                                        onClick={closeRegistrationPopup}
                                    >
                                        X
                                    </button>
                                    <h2 className="text-xl font-semibold mb-2 text-center">Farmer Registration</h2>
                                    <form className="space-y-" onSubmit={handleSubmit}>
                                        <h3 className="text-lg font-semibold text-left ">Farmer Details:</h3>
                                        <div className="grid grid-cols-3 gap-4 text-left">
                                            <div>
                                                <label className="block text-sm font-medium">Farmer Name</label>
                                                <TextField
                                                    type="text"
                                                    // margin='normal'
                                                    required
                                                    variant='outlined'
                                                    id="farmer-name"
                                                    className="mt-0"
                                                    onChange={(e) => setFarmerName(e.target.value)}
                                                    sx={{
                                                        width: '250px',
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '4px',
                                                            padding: '0px 0px',
                                                            fontSize: '14px',
                                                            backgroundColor: '#fff',
                                                            border: '1px solid #616161',
                                                            paddingRight: '0px',
                                                            height: '38px',
                                                        },
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <label className="block text-sm font-medium">Pincode</label>
                                                <TextField
                                                    type="text"
                                                    // margin='normal'
                                                    required
                                                    variant='outlined'
                                                    id="pincode"
                                                    className="mt-0"
                                                    onBlur={pincodeverification}
                                                    sx={{
                                                        width: '250px',
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '4px',
                                                            padding: '0px 0px',
                                                            fontSize: '14px',
                                                            backgroundColor: '#fff',
                                                            border: '1px solid #616161',
                                                            paddingRight: '0px',
                                                            height: '38px',
                                                        },
                                                    }}
                                                />
                                                <span className="text-red-500">{pinerror}</span>
                                            </div>
                                            <div>
                                                <label className="block text-sm font-medium">Village</label>
                                                <TextField
                                                    type="text"
                                                    // margin='normal'
                                                    required
                                                    variant='outlined'
                                                    id="village"
                                                    className="mt-0"
                                                    onChange={(e) => setVillage(e.target.value)}
                                                    sx={{
                                                        width: '250px',
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '4px',
                                                            padding: '0px 0px',
                                                            fontSize: '14px',
                                                            backgroundColor: '#fff',
                                                            border: '1px solid #616161',
                                                            paddingRight: '0px',
                                                            height: '38px',
                                                        },
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <label className="block text-sm font-medium">District</label>
                                                <TextField
                                                    type="text"
                                                    // margin='normal'
                                                    required
                                                    variant='outlined'
                                                    id="district"
                                                    className="mt-0"
                                                    onChange={(e) => setDistrict(e.target.value)}
                                                    value={district1}
                                                    sx={{
                                                        width: '250px',
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '4px',
                                                            padding: '0px 0px',
                                                            fontSize: '14px',
                                                            backgroundColor: '#fff',
                                                            border: '1px solid #616161',
                                                            paddingRight: '0px',
                                                            height: '38px',
                                                        },
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <label className="block text-sm font-medium">State</label>
                                                <TextField
                                                    type="text"
                                                    // margin='normal'
                                                    required
                                                    variant='outlined'
                                                    id="state"
                                                    className="mt-0"
                                                    onChange={(e) => setState(e.target.value)}
                                                    value={state1}
                                                    sx={{
                                                        width: '250px',
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '4px',
                                                            padding: '0px 0px',
                                                            fontSize: '14px',
                                                            backgroundColor: '#fff',
                                                            border: '1px solid #616161',
                                                            paddingRight: '0px',
                                                            height: '38px',
                                                        },
                                                    }}
                                                />
                                            </div>
                                            {/* <div>
                                        <label className="block text-sm font-medium">Total Farm Area</label>
                                        <input
                                            type="number"
                                            className="w-full px-3 py-2 border border-black h-8 rounded-sm"
                                            onChange={(e) => setTotalFarmArea(e.target.value)}
                                        />
                                    </div> */}
                                            <div>
                                                <label className="block text-sm font-medium">Leased Area in Acre</label>
                                                <TextField
                                                    type="number"
                                                    // margin='normal'
                                                    required
                                                    variant='outlined'
                                                    id="leasedarea"
                                                    className="mt-0"
                                                    onChange={(e) => setLeasedArea(Number(e.target.value))}
                                                    sx={{
                                                        width: '250px',
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '4px',
                                                            padding: '0px 0px',
                                                            fontSize: '14px',
                                                            backgroundColor: '#fff',
                                                            border: '1px solid #616161',
                                                            paddingRight: '0px',
                                                            height: '38px',
                                                        },
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <label className="block text-sm font-medium">Owned Area in Acre</label>
                                                <TextField
                                                    type="number"
                                                    // margin='normal'
                                                    required
                                                    variant='outlined'
                                                    id="ownedarea"
                                                    className="mt-0"
                                                    onChange={(e) => setOwnedArea(Number(e.target.value))}
                                                    sx={{
                                                        width: '250px',
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '4px',
                                                            padding: '0px 0px',
                                                            fontSize: '14px',
                                                            backgroundColor: '#fff',
                                                            border: '1px solid #616161',
                                                            paddingRight: '0px',
                                                            height: '38px',
                                                            margin: '0'
                                                        },
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <label className="block text-sm font-medium">Type of Cultivation</label>
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo1"
                                                    options={cultivation_practice}
                                                    value={typeOfCultivation}
                                                    onChange={handle_option}
                                                    getOptionLabel={(option) => option.name}
                                                    isOptionEqualToValue={(option, value) => option.name === value.name}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            size="small"
                                                            required
                                                            sx={{
                                                                width: '250px',
                                                                '& .MuiOutlinedInput-root': {
                                                                    borderRadius: '4px',
                                                                    padding: '4px 8px',
                                                                    fontSize: '14px',
                                                                    backgroundColor: '#fff',
                                                                    border: '1px solid #616161',
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                    sx={{
                                                        width: '330px',
                                                        '& .MuiAutocomplete-popupIndicator': {
                                                            color: '#000',
                                                        },
                                                        '& .MuiAutocomplete-input': {
                                                            padding: '6px 8px',
                                                            fontSize: '14px',
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <h3 className="text-lg font-semibold pt-3 pb-3 text-left">Bank Details:</h3>
                                        <div className="grid grid-cols-3 gap-4 text-left">
                                            <div>
                                                <label className="block text-sm font-medium">Bank Name</label>
                                                <TextField
                                                    type="text"
                                                    // margin='normal'
                                                    // required
                                                    variant='outlined'
                                                    id="bankname"
                                                    className="mt-0"
                                                    onChange={(e) => setBankName(e.target.value)}
                                                    sx={{
                                                        width: '250px',
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '4px',
                                                            padding: '0px 0px',
                                                            fontSize: '14px',
                                                            backgroundColor: '#fff',
                                                            border: '1px solid #616161',
                                                            paddingRight: '0px',
                                                            height: '38px',
                                                        },
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <label className="block text-sm font-medium">Holder Name</label>
                                                <TextField
                                                    type="text"
                                                    // margin='normal'
                                                    // required
                                                    variant='outlined'
                                                    id="holdername"
                                                    className="mt-0"
                                                    onChange={(e) => setHolderName(e.target.value)}
                                                    sx={{
                                                        width: '250px',
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '4px',
                                                            padding: '0px 0px',
                                                            fontSize: '14px',
                                                            backgroundColor: '#fff',
                                                            border: '1px solid #616161',
                                                            paddingRight: '0px',
                                                            height: '38px',
                                                        },
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <label className="block text-sm font-medium">Account Number</label>
                                                <TextField
                                                    type="text"
                                                    // margin='normal'
                                                    // required
                                                    variant='outlined'
                                                    id="accnumber"
                                                    className="mt-0"
                                                    onChange={(e) => setAccountNumber(e.target.value)}
                                                    sx={{
                                                        width: '250px',
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '4px',
                                                            padding: '0px 0px',
                                                            fontSize: '14px',
                                                            backgroundColor: '#fff',
                                                            border: '1px solid #616161',
                                                            paddingRight: '0px',
                                                            height: '38px',
                                                        },
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <label className="block text-sm font-medium">IFSC Code</label>
                                                <TextField
                                                    type="text"
                                                    // margin='normal'
                                                    // required
                                                    variant='outlined'
                                                    id="state"
                                                    className="mt-0"
                                                    onChange={(e) => setIfscCode(e.target.value)}
                                                    sx={{
                                                        width: '250px',
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '4px',
                                                            padding: '0px 0px',
                                                            fontSize: '14px',
                                                            backgroundColor: '#fff',
                                                            border: '1px solid #616161',
                                                            paddingRight: '0px',
                                                            height: '38px',
                                                        },
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <label className="block text-sm font-medium">PAN Number</label>
                                                <TextField
                                                    type="text"
                                                    // margin='normal'
                                                    // required
                                                    variant='outlined'
                                                    id="state"
                                                    className="mt-0"
                                                    onChange={(e) => setPanNumber(e.target.value)}
                                                    sx={{
                                                        width: '250px',
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '4px',
                                                            padding: '0px 0px',
                                                            fontSize: '14px',
                                                            backgroundColor: '#fff',
                                                            border: '1px solid #616161',
                                                            paddingRight: '0px',
                                                            height: '38px',
                                                        },
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <label className="block text-sm font-medium">Aadhaar Number</label>
                                                <TextField
                                                    type="text"
                                                    // margin='normal'
                                                    // required
                                                    variant='outlined'
                                                    id="state"
                                                    className="mt-0"
                                                    onChange={(e) => setAadhaarNumber(e.target.value)}
                                                    sx={{
                                                        width: '250px',
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '4px',
                                                            padding: '0px 0px',
                                                            fontSize: '14px',
                                                            backgroundColor: '#fff',
                                                            border: '1px solid #616161',
                                                            paddingRight: '0px',
                                                            height: '38px',
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex justify-center mt-6">
                                            <button
                                                type="submit"
                                                className="bg-[#3fc041] text-white px-6 py-2 rounded-sm 
                                                w-1/2"
                                            >
                                                Register
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div >
        </div >
    );

}

export default Farmer_registration;
